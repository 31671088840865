var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('HeaderTab',{attrs:{"title":_vm.$t('global.titre_tiers_liste'),"return_action":'navigationBack'}}),_c('div',{attrs:{"id":"content"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"box"},[_c('CustomTable',{ref:"table",attrs:{"id_table":"tiers","busy":_vm.table_busy,"primaryKey":"tiers_id","hrefsRoutes":_vm.config_table_hrefs,"hide_if_empty":true,"items":_vm.tiers,"base-filters":_vm.filters,"externSlotColumns":['tiers_tags', 'all_client_account', 'tiers_mail', 'phones_number']},on:{"update:busy":function($event){_vm.table_busy=$event}},scopedSlots:_vm._u([{key:"custom-slot-cell(tiers_mail)",fn:function(ref){
var data = ref.data;
return [_c('e-copybox',{attrs:{"classes":"row","mail":"","customtable":"","value":data.tiers_mail}})]}},{key:"custom-slot-cell(phones_number)",fn:function(ref){
var data = ref.data;
return _vm._l((data.phones_number.split('<br>')),function(phone_number,index){return _c('span',{key:index},[_c('e-copybox',{attrs:{"classes":"row","phone":"","customtable":"","value":phone_number}})],1)})}},{key:"custom-slot-cell(tiers_tags)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"row"},_vm._l((data.tiers_tags),function(color){return _c('span',{key:color,staticClass:"tags",style:('background-color:'+color)})}),0)]}},{key:"custom-slot-cell(all_client_account)",fn:function(ref){
var data = ref.data;
return _vm._l((data.all_client_account),function(client_account){return _c('span',{key:client_account.tiersclientaccount_id},[_vm._v(" "+_vm._s(client_account.entity.tiers_rs)+" : "+_vm._s(client_account.tiersclientaccount_number)),_c('br')])})}}],null,true)})],1)])])])]),_c('TiersModal',{attrs:{"from":"liste"}}),_c('ModalEditQualification',{ref:"modal_edit_qualification"}),_c('ModalPrintInfosTiers',{ref:"modal_print_infos_tiers"}),_c('ModalSendMail',{ref:"modal_send_mail"}),_c('TiersImport')],1)}
var staticRenderFns = []

export { render, staticRenderFns }